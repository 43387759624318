/**
 * 获取支付方式工具类
 */

import config from '@/config'

const getToPageRouteName = function () {
  const payWay = getPayWay();
  return payWay ? payWay.routeName : null
}

const getPayWay = function () {

  const userAgent = navigator.userAgent;

  if (userAgent.indexOf("MicroMessenger") >= 0) {
    if (userAgent.toLocaleLowerCase().indexOf('miniprogram') >= 0) {
      return config.payWay.WXPAY_MINI
    } else {
      return config.payWay.WXPAY;
    }
  }

  if (userAgent.indexOf("AlipayClient") >= 0) {
    if (userAgent.toLocaleLowerCase().indexOf('miniprogram') >= 0) {
      return config.payWay.ALIPAY_MINI;
    } else {
      return config.payWay.ALIPAY;
    }
  }
  // NODE_ENV=development
  if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_DEV_WAY_CODE) {
    return config.payWay.WXPAY;
  }
  return config.payWay.YSFPAY;

}
const getfilterList = function (list, name) {
  const newList = list.filter(obj => {
    if (obj.wayCode.includes(name)) {
      return obj
    }
  })
  return newList
}
const mapListCode = function (list, name, wayCode, payName) {
  let newList = []
  list.forEach(obj => {
    console.log(obj, name, wayCode);
    if (obj.wayCode.includes(name) && obj.wayCode == wayCode) {
      newList.push({ wayCode: wayCode, wayName: payName })
    }
  })
  return newList
}
const convertWayList = function (list) {
  // const payway = getPayWay()
  let newList = []
  //增加应用过滤,wx/alipay/ysf
  //是否在公众号下只支持JSAPI?或程序只显示LITE?
  list.filter(obj => {
    if (obj.wayCode.includes('WX_JSAPI') || obj.wayCode.includes('WX_LITE')) {
      newList.push({ wayCode: obj.wayCode, wayName: '微信支付' })    
    } else if (obj.wayCode.includes('ALI_JSAPI') || obj.wayCode.includes('ALI_APP')) {
      newList.push({ wayCode: obj.wayCode, wayName: '支付宝' })
    } else if (obj.wayCode.includes('YSF')) {
      newList.push({ wayCode: obj.wayCode, wayName: '云闪付' })
    } else {
      newList.push({ wayCode: obj.wayCode, wayName: obj.wayName })
    }
  })
  return newList
}
const getPayWayList = function (wayList) {
  console.log('wayList', wayList);
  let list = wayList
  // const wxlist = getfilterList(list, 'WX')
  // const zfblist = getfilterList(list, 'ALI')
  // const ysflist = getfilterList(list, 'YSF')
  // const sdwxlist = getfilterList(list, 'SD_WX')
  // const sdalilist = getfilterList(list, 'SD_ALI')

  // const payway = getPayWay()
  // console.log(payway)

  list = convertWayList(list)
  // console.log('lllll=',list)
  // if (payway.mini) {
  //   if (sdwxlist.length) {
  //     // list = list.filter(obj => obj.wayCode == 'SD_WX_LITE')
  //     list = this.mapListCode(list, 'SD_WX', 'SD_WX_LITE', '微信支付')
  //   } else if (sdalilist.length) {
  //     // list = list.filter(obj => obj.wayCode == 'SD_ALI_APP')
  //     list = this.mapListCode(list, 'ALI', 'SD_ALI_APP', '支付宝')
  //   } else {
  //     list = list.filter(obj => obj.wayCode == payway.wayCode)
  //   } 
  // } else {
  //   if (wxlist.length) {
  //     list = this.mapListCode(list, 'WX', 'WX_JSAPI', '微信支付')
  //     if (sdwxlist.length) {
  //       list = [...list, ...this.mapListCode(sdwxlist, 'SD_WX', 'SD_WX_JSAPI', '微信支付')]
  //     }
  //   }
  //   if (zfblist.length) {
  //     list = this.mapListCode(list, 'ALI', 'ALI_JSAPI', '支付宝')
  //     if (sdalilist.length) {
  //       list = [...list, ...this.mapListCode(sdwxlist, 'SD_ALI', 'SD_ALI_JSAPI', '支付宝')]
  //     }
  //   }
  //   if (ysflist.length) {
  //     list = this.mapListCode(list, 'YSF', 'YSF_JSAPI', '云闪付')
  //   }
  // }
  list = list.filter((obj, index, self) =>
    index === self.findIndex((t) => (
      t.wayCode === obj.wayCode && t.wayName === obj.wayName
    ))
  );
  // }
  console.log(list, 'list');
  return list;
}
const getImgIcon = function (wayCode) {
  if (wayCode.includes('WX')) {
    return require("../assets/images/wx.png")
  } else if (wayCode.includes('ALI')) {
    return require("../assets/images/zfb.jpeg")
  } else {
    return require("../assets/images/ysf.jpg")
  }
}
export default {
  getToPageRouteName: getToPageRouteName,
  getPayWay: getPayWay,
  getfilterList: getfilterList,
  mapListCode: mapListCode,
  getPayWayList: getPayWayList,
  getImgIcon: getImgIcon

}
